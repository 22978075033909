<template>
  <div class="notfound">
    <h1>404: Not Found</h1>
    <a href="/" class="" size="sm"><b-icon icon="arrow-clockwise"></b-icon> Fresh Quote</a>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.notfound {
  font-family: 'IBM Plex Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
</style>
